/** @jsxImportSource react */
import { cart } from '$/store/cart'
import { useStore } from '@nanostores/react'
import ProductUpsell from './ProductUpsell/ProductUpsell'
import type { Product } from '$types'

export default function FeaturedList({ title, products }: { title: string; products: Product[] }) {
  const { cartItems, totals } = useStore(cart)
  const displayedProducts = products.filter((i) => cartItems.findIndex((j) => j.productId === i.id) === -1).slice(0, 3)

  return displayedProducts.length ? (
    <div className="flow flow-sm u-mx-[-15px] u--mb-6 u-mt-4 u-bg-light u-px-[15px] u-py-4 desktop:u--mx-4 desktop:u-px-4">
      <h2 className="label-lg u-text-center">{title}</h2>
      <ul>
        {displayedProducts.map((product, i) => (
          <ProductUpsell
            product={product}
            key={i}
            productDiscountedRate={totals?.productDiscountedRate || 1}
            size="sm"
            className="u-mt-2 first:u-mt-0"
          />
        ))}
      </ul>
    </div>
  ) : null
}
